.filters_slide_bar_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 50px;
  @include flex-center;
  background: rgba($color: $color-black, $alpha: 0.5);
  @include media-max(1200px) {
    padding: 20px;
  }
  .filters_slide_bar_holder {
    background: $color-white;
    border-radius: 10px;
    padding: 30px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
    @include media-max(1200px) {
      max-height: 90vh;
    }
    form {
      @include full-width;
      .group {
        h3 {
          font-size: 16px;
        }
      }
      .holder {
        @include full-width;
        display: grid;
        grid-template-columns: 250px 1fr;
        grid-gap: 30px;
        align-items: start;
        @include media-max(1200px) {
          grid-template-columns: 100%;
        }
        .left {
          border: 1px solid #ddd;
          .group {
            border-bottom: 1px solid #ddd;
            padding: 15px 10px 0 10px;
            &:last-child {
              border-bottom: 0;
            }
          }
        }
        .right {
          padding-top: 20px;
          .dropdowns_row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
            @include media-max(900px) {
              grid-template-columns: 100%;
            }
            h3 {
              display: none;
            }
          }
          .web_price_row {
            margin: 20px 0;
            h3 {
              margin-bottom: 25px;
            }
            .price_from_to {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 30px;
            }
          }
          .price_from_to {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
          }
          .search_select_wrapper {
            &.mt-20 {
              margin: 35px 0 !important;
            }
          }
          .form_checkbox {
            margin-bottom: 40px;
          }
          .data_pickers {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
          }
          .fixed_buttons {
            margin-top: 30px;
            justify-content: flex-end;
            display: grid;
            grid-template-columns: repeat(3, 200px);
            grid-gap: 30px;
            @include media-max(900px) {
              grid-template-columns: 100%;
            }
          }
        }
      }
    }
  }
}
