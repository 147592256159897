.cms_newsletter {
  .skeleton_inner .skeleton_content {
    grid-template-columns: 200px 1fr 25px;
    @include media-max(900px) {
      grid-template-columns: 150px 1fr 25px;
    }
    @include media-max(500px) {
      grid-template-columns: 100px 1fr 25px;
    }
  }
  .top_custom {
    .products_dropdown {
      @include flex-align-center;
      @include media-max(600px) {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 0 10px;
      }
      .search_select_wrapper {
        width: 250px;
        margin-right: 20px;
        @include media-max(600px) {
          width: 100%;
        }
      }
    }
  }
  @include media-max(600px) {
    .align_row {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0 !important;
      .top_custom {
        margin: 20px 0 0 0;
      }
    }
  }
  .table-newsletter {
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 300px 1fr 35px;
      @include media-max(700px) {
        grid-template-columns: 110px 1fr 35px;
      }
    }
  }
}
