.linking_categories_screen_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 30px;
  @include media-max(1000px) {
    grid-template-columns: 100%;
    grid-gap: 30px 0;
  }
  .dropdowns_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 20px;
    align-items: center;
    margin-bottom: 20px;
    @include media-max(500px) {
      grid-template-columns: 100%;
      grid-gap: 20px 0;
    }
    .search_select_wrapper {
      width: 100% !important;
      margin: 0;
    }
  }
}

.linking_categories_screen {
  .search_select_wrapper.no-scroll .search_select_holder .root p {
    color: $color-text;
  }
  .table_helper {
    @include media-max(950px) {
      flex-direction: column;
      .align_row:first-child {
        order: 2;
        @include media-max(700px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          .search_select_wrapper {
            margin: 20px 20px 0 0;
            @include media-max(600px) {
              margin: 0;
            }
          }
        }
      }
      .align_row:last-child {
        order: 1;
      }
      .align_row {
        width: 100%;
        .search_holder {
          margin: 0 0 20px 0;
          @include media-max(750px) {
            margin: 0;
          }
        }
      }
    }
  }
}

.table-linkingCategories {
  .header,
  .table_row {
    display: grid;
    grid-template-columns: 1fr 250px 100px;
    align-items: center;
    @include media-max(1450px) {
      grid-template-columns: 1fr 150px 100px;
    }
    @include media-max(1000px) {
      grid-template-columns: 1fr 250px 100px;
    }
    @include media-max(600px) {
      grid-template-columns: 1fr 100px 100px;
    }
    & > .table_cell:last-child {
      @include flex-align-center;
      justify-content: flex-end;
      overflow: unset !important;
    }
    .select_form {
      width: 350px;
      @include flex-align-center;
      @include media-max(850px) {
        display: none;
      }
      .search_select_wrapper {
        margin-right: 10px;
      }
      .tooltip_wrap {
        .title {
          left: -130px;
        }
      }
    }
    .tooltip_wrap {
      margin-right: 5px;
      .icon {
        color: $color-danger;
        cursor: pointer;
      }
    }
  }
  .modal_holder {
    position: relative;
    width: 30px;
    height: 30px;
    .tooltip_wrap {
      z-index: unset;
      .icon {
        z-index: unset;
      }
    }
    .connected_categories_modal {
      position: absolute;
      top: 110%;
      right: 0;
      background: $color-white;
      border-radius: 6px;
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: $color-text;
      z-index: 5;
      overflow-y: scroll;
      max-height: 290px;
      @include media-max(500px) {
        height: 110px;
      }
      @include media-min(500px) {
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: rgb(212, 212, 212);
          border-radius: $radius-pill;
        }
      }
      @include media-max(650px) {
        top: 103%;
      }
      .align_row {
        padding: 5px 15px;
        @include flex-between;
        cursor: pointer;
        @include media-max(650px) {
          padding: 3px 10px;
        }
        & > div {
          @include media-max(650px) {
            white-space: normal !important;
            width: 150px;
          }
        }
        &:hover {
          background: $color-grey-10;
        }
        button {
          margin-left: 10px;
        }
      }
    }
  }
}

.search_select_wrapper {
  &.table_header_select {
    width: 220px;
    .root {
      p {
        font-size: 13px;
      }
    }
  }
}

.search_select_wrapper {
  &.no-scroll {
    .search_select_holder .dropdown_holder .dropdown_content ul {
      margin: 0;
    }
    .root {
      p {
        font-size: 13px;
      }
    }
  }
}

.mobile_connect_form_holder {
  display: none;
  @include media-max(850px) {
    @include flex-align-center;
  }
  .custom_modal_wrapper {
    @include fixed-full;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    @include flex-center;
    .custom_modal_holder {
      background: $color-grey-10;
      padding: 30px 50px;
      width: 70%;
      height: 300px;
      border-radius: 8px;
      position: relative;
      @include media-max(650px) {
        width: 90%;
        padding: 20px;
      }
      .close {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      .search_select_wrapper .search_select_holder {
        background: $color-grey-10;
      }
      @include flex-column-center;
      h2 {
        text-align: center;
        font-weight: 300;
        margin-bottom: 20px;
        font-size: 20px;
      }
      form {
        display: block;
        width: 100%;
        .buttons {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          align-items: center;
          grid-gap: 0 20px;
          margin-top: 20px;
        }
      }
    }
  }
}

.our_categories_table {
  .our_table {
    button {
      margin: 0 0 20px auto;
      @include media-max(1000px) {
        margin-left: 0;
      }
    }
    .table_header {
      background: #e7e3e3;
      height: 50px;
      padding: 0 10px;
      @include flex-between;
      p {
        @include table-text;
        width: unset;
        @include media-max(1600px) {
          font-size: 13px;
        }
        @include media-max(1400px) {
          font-size: 11px;
        }
      }
    }
  }
  .table {
    .sub {
      background: rgb(241, 241, 241);
      padding-left: 20px;
      position: relative;
      &.level_3,
      &.level_5 {
        background: #fff;
        .table_row_wrap {
          &:nth-child(even) {
            background: #fff;
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 24px;
        left: 9px;
        width: 2px;
        background: #e7e3e3;
      }
      .table_row_wrap {
        &:nth-child(even) {
          background: rgb(241, 241, 241);
        }
        .table_row {
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            left: -9px;
            height: 2px;
            background: #e7e3e3;
          }
        }
      }
    }
    .table_row_wrap {
      cursor: pointer;
      position: relative;
      &:nth-child(even) {
        background: #f8f8f8;
      }
      .table_row {
        position: relative;
        height: 50px;
        padding: 0 10px;
        display: grid;
        grid-template-columns: 30px 1fr 40px;
        align-items: center;
        .table_cell {
          @include table-text;
          width: unset;
          @include media-max(1600px) {
            font-size: 13px;
          }
          @include media-max(1400px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}

.supplier_categories_table {
  .table_helper {
    position: relative;
    .dropdowns_wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: calc(100% + 20px);
    }
  }
  .table {
    margin-top: 80px !important;
    @include media-max(500px) {
      margin-top: 140px !important;
    }
  }
}
