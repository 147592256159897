.cms_content {
  .form_nav_wrapper {
    @include flex-align-center;
    background: $color-white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 20px;
    border-radius: 0.5rem 0.5rem 0 0;
    margin: -20px -20px 50px -20px;
    @include media-max(500px) {
      margin: -15px -15px 50px -15px;
    }
    .nav_item {
      color: $color-grey-02;
      margin-right: 20px;
      margin-bottom: -1px;
      cursor: pointer;
      @include transition;
      font-size: 16px;
      font-weight: 400;
      padding: 15px 0;
      border-bottom: 1px solid transparent;
      @include media-max(500px) {
        font-size: 14px;
        padding: 12px 0;
        margin-right: 15px;
      }
      &.active {
        color: $color-purple;
        border-bottom: 1px solid $color-purple;
      }
    }
  }
}
