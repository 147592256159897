.upload_pricelist {
  width: 100%;
  .grid_x2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 30px;
    @include media-max(1100px) {
      grid-template-columns: 100%;
      grid-gap: 30px 0;
    }
  }
  form {
    width: 100%;
    .left {
      .dropzone_holder {
        width: 100%;
        height: 250px;
        border: 2px dashed $color-grey-01;
        position: relative;
        padding: 20px;
        cursor: pointer;
        @include media-max(700px) {
          height: 200px;
        }
        input {
          @include absolute-full;
          font-size: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 3;
        }
        .util {
          @include absolute-full;
          @include flex-column-center;
          svg {
            color: $color-purple;
            font-size: 5rem;
            @include media-max(700px) {
              font-size: 4rem;
            }
          }
          p {
            margin-top: 10px;
            color: $color-text;
            font-size: 20px;
            text-align: center;
            @include media-max(700px) {
              font-size: 17px;
            }
          }
        }
      }
      .error_msg {
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
        @include media-max(700px) {
          font-size: 14px;
        }
      }
    }
    .print_uploaded_file {
      margin-top: 20px;
      & > div {
        @include flex-between;
        padding: 5px 15px;
        border-radius: 8px;
        background: $color-grey-10;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
    .right {
      width: 100%;
      @include flex-column;
      align-items: center;
      justify-content: center;
      position: relative;
      .radio_group.MuiFormGroup-root {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: unset;
        justify-content: center;
        @include media-max(700px) {
          flex-direction: column;
        }
        .MuiRadio-colorSecondary.Mui-checked {
          color: $color-purple;
        }
      }
      button {
        margin-top: 80px;
      }
      .form_checkbox {
        position: absolute;
        @include media-max(700px) {
          label {
            font-size: 12px;
          }
        }
      }
    }
  }
}
