.table-ratings {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 250px 300px 100px 1fr 130px 70px;
    @include media-max(1500px) {
      grid-template-columns: 200px 250px 100px 1fr 120px 70px;
    }
    @include media-max(1000px) {
      grid-template-columns: 200px 250px 1fr 70px;
    }
    @include media-max(800px) {
      grid-template-columns: 1fr 1fr 70px;
    }
    .table_cell {
      .align_row {
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-gap: 0 10px;
      }
      .ticket {
        background: rgba(234, 84, 85, 0.15);
        color: #ea5455;
        @include flex-center;
        padding: 4px 10px;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 100px;
        &.active {
          background: rgba(40, 199, 111, 0.15);
          color: #28c76f;
        }
      }
    }
  }
  .table_row {
    cursor: pointer;
  }
  .header {
    @include media-max(1000px) {
      padding-left: 45px !important;
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
          width: 200px !important;
          @include media-max(800px) {
            flex: 1 !important;
            width: auto !important;
          }
        }
        &:nth-child(2) {
          display: flex !important;
          width: 250px !important;
          @include media-max(800px) {
            display: none !important;
          }
        }
        &:nth-child(3) {
          display: flex !important;
          flex: 1 !important;
        }
        &:nth-child(6) {
          width: 70px !important;
          display: flex !important;
        }
        display: none;
      }
    }
  }
  .table_row {
    @include media-max(1000px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(1) {
          display: flex !important;
          width: 250px !important;
        }
        &:nth-child(2) {
          margin-left: 10px;
          display: flex !important;
          width: 200px !important;
          @include media-max(800px) {
            flex: 1 !important;
            width: auto !important;
          }
        }
        &:nth-child(3) {
          display: flex !important;
          width: 250px !important;
          @include media-max(800px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
          flex: 1 !important;
        }
        &:nth-child(7) {
          display: flex !important;
          width: 70px !important;
        }
        display: none;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(1000px) {
      display: block;
      .mobile_table_row:nth-child(1) {
        display: none;
        @include media-max(800px) {
          display: grid;
        }
      }
    }
  }
}

.show_rating_page {
  .item_row {
    display: grid;
    grid-template-columns: 120px 1fr;
    align-items: center;
    grid-gap: 0 10px;
    padding: 10px 0;
    .label {
      @include flex-align-center;
      @include media-max(600px) {
        font-size: 12px;
      }
    }
    .content {
      @include flex-align-center;
      @include media-max(600px) {
        font-size: 12px;
      }
      .avatar {
        margin-right: 10px;
      }
      .ticket {
        background: rgba(234, 84, 85, 0.15);
        color: #ea5455;
        @include flex-center;
        padding: 4px 10px;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 100px;
        &.active {
          background: rgba(40, 199, 111, 0.15);
          color: #28c76f;
        }
      }
      a {
        color: $color-purple;
        font-size: 16px;
        @include media-max(600px) {
          font-size: 12px;
        }
      }
    }
  }
  .buttons {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: end;
    grid-gap: 10px;
  }
}
