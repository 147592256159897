.questions_and_answers_cms_page {
  .table_helper .search_holder {
    display: none;
  }
  .table-questions_and_answers {
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 200px 1fr 120px 35px;
      @include media-max(900px) {
        grid-template-columns: 100px 1fr 120px 35px;
      }
      @include media-max(500px) {
        grid-template-columns: 70px 1fr 100px 35px;
      }
      .table_cell {
        .ticket {
          background: rgba(234, 84, 85, 0.15);
          color: #ea5455;
          @include flex-center;
          padding: 4px 10px;
          font-size: 0.8rem;
          font-weight: 500;
          border-radius: 100px;
          &.active {
            background: rgba(40, 199, 111, 0.15);
            color: #28c76f;
          }
          @include media-max(900px) {
            font-size: 10px;
          }
        }
      }
    }
    .table_row {
      cursor: pointer;
    }
  }
}

.show_questions_and_answer_page {
  .item_row {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-gap: 0 10px;
    padding: 10px 0;
    @include media-max(600px) {
      grid-template-columns: 80px 1fr;
    }
    .label {
      @include media-max(600px) {
        font-size: 12px;
      }
    }
    .content {
      @include flex-align-center;
      @include media-max(600px) {
        font-size: 12px;
      }
      .ticket {
        background: rgba(234, 84, 85, 0.15);
        color: #ea5455;
        @include flex-center;
        padding: 4px 10px;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 100px;
        &.active {
          background: rgba(40, 199, 111, 0.15);
          color: #28c76f;
        }
      }
      a {
        color: $color-purple;
        font-size: 16px;
        @include media-max(600px) {
          font-size: 12px;
        }
      }
    }
  }
  form {
    .area_field {
      margin: 0;
    }
    label {
      display: none;
    }
    button {
      margin-top: 20px;
    }
  }
  .buttons {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: end;
    grid-gap: 10px;
  }
}
