.products_export {
  .box_holder {
    position: relative;
    .edit_btn {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
  }
  .table_helper {
    .table_helper_left {
      display: grid;
      grid-template-columns: 300px auto;
      grid-gap: 20px;
      @include media-max(1000px) {
        grid-template-columns: 200px auto;
        .search_select_wrapper .search_select_holder .root p {
          font-size: 14px;
        }
      }
      @include media-max(750px) {
        grid-template-columns: 1fr auto;
      }
      .search_select_wrapper
        .search_select_holder
        .dropdown_holder
        .dropdown_content
        ul
        li {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        button.icon_button {
          width: 20px;
          height: 20px;
        }
      }
    }
    @include media-max(750px) {
      .align_row:nth-child(1) {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 20px;
      }
    }
  }
  .table {
    .table_header {
      background: #e7e3e3;
      height: 50px;
      padding: 0 10px;
      @include flex-between;
      p {
        @include table-text;
        width: unset;
        @include media-max(1600px) {
          font-size: 13px;
        }
        @include media-max(1400px) {
          font-size: 11px;
        }
      }
    }
    .table_row_wrap {
      cursor: pointer;
      position: relative;
      &:nth-child(even) {
        background: #f8f8f8;
      }
      .sub {
        background: rgb(241, 241, 241);
        padding-left: 20px;
        position: relative;
        &.level_3,
        &.level_5 {
          background: #fff;
          .table_row_wrap {
            &:nth-child(even) {
              background: #fff;
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 24px;
          left: 9px;
          width: 2px;
          background: #e7e3e3;
        }
        .table_row_wrap {
          &:nth-child(even) {
            background: rgb(241, 241, 241);
          }
          .table_row {
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 12px;
              left: -9px;
              height: 2px;
              background: #e7e3e3;
            }
          }
        }
      }
      .table_row {
        position: relative;
        height: 50px;
        padding: 0 10px;
        display: grid;
        grid-template-columns: 30px 1fr 40px;
        align-items: center;
        .table_cell {
          @include table-text;
          width: unset;
          @include media-max(1600px) {
            font-size: 13px;
          }
          @include media-max(1400px) {
            font-size: 11px;
          }
        }
      }
    }
  }
}

.products_export_add_client_page_inner {
  .products_export_add_client_page_outer {
    .box_holder {
      padding: 40px 20px;
    }
    .our_categories_table {
      margin: 20px 0;
      button {
        display: none;
      }
      .table_header {
        background: #e7e3e3;
        height: 50px;
        padding: 0 10px;
        @include flex-between;
        p {
          @include table-text;
          width: unset;
          @include media-max(1600px) {
            font-size: 13px;
          }
          @include media-max(1400px) {
            font-size: 11px;
          }
        }
      }
      .sub {
        background: rgb(241, 241, 241);
        padding-left: 20px;
        position: relative;
        &.level_3,
        &.level_5 {
          background: #fff;
          .table_row_wrap {
            &:nth-child(even) {
              background: #fff;
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 24px;
          left: 9px;
          width: 2px;
          background: #e7e3e3;
        }
        .table_row_wrap {
          &:nth-child(even) {
            background: rgb(241, 241, 241);
          }
          .table_row {
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 12px;
              left: -9px;
              height: 2px;
              background: #e7e3e3;
            }
          }
        }
      }
      .table_row_wrap {
        cursor: pointer;
        position: relative;
        &:nth-child(even) {
          background: #f8f8f8;
        }
        .table_row {
          position: relative;
          height: 50px;
          padding: 0 10px;
          display: grid;
          grid-template-columns: 30px 1fr 40px;
          align-items: center;
          .table_cell {
            @include table-text;
            width: unset;
            @include media-max(1600px) {
              font-size: 13px;
            }
            @include media-max(1400px) {
              font-size: 11px;
            }
          }
        }
      }
    }
    .buttons {
      @include flex-align-center;
      justify-content: flex-end;
      button.default {
        margin-right: 20px;
      }
    }
  }
}

.modal_inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: $color-black, $alpha: 0.5);
  z-index: 99999999;
  @include flex-center;
  .modal_outer {
    margin: 20px;
    padding: 20px;
    background: $color-white;
    border-radius: 10px;
    form {
      min-width: 400px;
      padding-top: 20px;
      @include media-max(500px) {
        min-width: 90%;
      }
      button {
        margin-top: 20px;
      }
    }
  }
}
