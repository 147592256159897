.cms_home_screen {
  .paginate {
    display: none !important;
  }
  .align_row {
    width: 100%;
  }
  .products_dropdown {
    @include flex-align-center;
    @include media-max(600px) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 0 10px;
    }
    .search_select_wrapper {
      width: 300px;
      margin-right: 20px;
      @include media-max(600px) {
        width: 100%;
        margin: 0;
        .search_select_holder .root p {
          font-size: 10px;
        }
      }
    }
  }
  .home_screen_products_table_inner {
    .header {
      background: #e7e3e3;
      height: 50px;
      padding: 0 10px;
    }
    .table_row {
      position: relative;
      height: 50px;
      padding: 0 10px;
    }
    .table_cell {
      @include table-text;
      @include media-max(1600px) {
        font-size: 13px;
      }
      @include media-max(1400px) {
        font-size: 11px;
      }
    }
    .home_screen_products_table_outer {
      & > div {
        & > div {
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 35px 200px 1fr 80px 40px;
      @include media-max(900px) {
        grid-template-columns: 35px 150px 1fr 80px 40px;
      }
      @include media-max(500px) {
        grid-template-columns: 35px 100px 1fr 30px 30px;
      }
    }
  }
  .home_screen_categories_table_inner {
    .header {
      background: #e7e3e3;
      height: 50px;
      padding: 0 10px;
    }
    .table_row {
      position: relative;
      height: 50px;
      padding: 0 10px;
    }
    .table_cell {
      @include table-text;
      @include media-max(1600px) {
        font-size: 13px;
      }
      @include media-max(1400px) {
        font-size: 11px;
      }
    }
    .home_screen_categories_table_outer {
      & > div {
        & > div {
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 35px 1fr 80px 70px;
      @include media-max(500px) {
        grid-template-columns: 35px 1fr 30px 70px;
      }
    }
  }

  .skeleton_inner .skeleton_content {
    grid-template-columns: 35px 200px 1fr 80px 40px;
    @include media-max(900px) {
      grid-template-columns: 35px 150px 1fr 80px 40px;
    }
    @include media-max(500px) {
      grid-template-columns: 35px 100px 1fr 30px 30px;
    }
  }
  .pagination_nav {
    display: none !important;
  }
}

.home_screen_categories_modal_inner {
  @include fixed-full;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  @include flex-center;
  .home_screen_categories_modal_outer {
    background: $color-white;
    width: 350px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    position: relative;
    padding: 30px;
    @include media-max(550px) {
      width: 90%;
    }
    .cancel_icon {
      position: absolute;
      top: -30px;
      right: -30px;
      width: 35px;
      height: 35px;
      color: $color-danger;
      cursor: pointer;
      @include media-max(550px) {
        display: none;
      }
    }
    .dropzone_wrapper {
      width: 100%;
      .input_file_holder {
        width: 200px;
        height: 200px;
        border: 1px dashed rgba($color: $color-black, $alpha: 0.4);
        position: relative;
        cursor: pointer;
        margin: 0 auto 30px auto;
        input {
          @include absolute-full;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
        span {
          @include absolute-full;
          @include flex-center;
          font-size: 18px;
          cursor: pointer;
          color: rgba($color: $color-black, $alpha: 0.4);
        }
      }
      .photo {
        @include inline-bg;
        position: relative;
        margin-bottom: 20px;
        svg {
          position: absolute;
          top: 5px;
          right: 5px;
          color: $color-danger;
          font-size: $size-icon-sm;
          cursor: pointer;
          font-size: 24px;
        }
      }
    }
    .buttons {
      margin-top: 30px;
      @include flex-align-center;
      justify-content: flex-end;
    }
  }
}

.cms_home_screen_sections {
  .paginate {
    display: none !important;
  }
  .pagination_nav {
    display: none !important;
  }
  .skeleton_inner .skeleton_content {
    grid-template-columns: 35px 1fr 25px 25px;
  }
  .home_screen_sections_table_inner {
    .header {
      background: #e7e3e3;
      height: 50px;
      padding: 0 10px;
    }
    .table_row {
      position: relative;
      height: 50px;
      padding: 0 10px;
    }
    .table_cell,
    a {
      @include table-text;
      @include media-max(1600px) {
        font-size: 13px;
      }
      @include media-max(1400px) {
        font-size: 11px;
      }
    }
    .home_screen_sections_table_outer {
      & > div {
        & > div {
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 35px 1fr 80px 70px;
      @include media-max(500px) {
        grid-template-columns: 35px 1fr 35px 70px;
      }
    }
  }
}

.home_screen_sections {
  .list_of_updated_items {
    margin-top: 20px;
    background: $color-grey-10;
    border-radius: 8px;
    .item {
      @include flex-between;
      font-size: 12px;
      padding: 10px 20px;
      border-bottom: 1px solid $color-white;
      &:last-child {
        border-bottom: 0;
      }
      svg {
        color: $color-danger;
        cursor: pointer;
      }
    }
  }
}

.cms_sections_show_section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 30px;
  @include media-max(1200px) {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
  .cms_page {
    margin-bottom: 20px;
    &:nth-child(2) {
      .data_no_found {
        display: none !important;
      }
    }
  }
  .skeleton_inner .skeleton_content {
    grid-template-columns: 35px 1fr 25px 25px;
  }
  .home_screen_products_table_inner {
    .header {
      background: #e7e3e3;
      height: 50px;
      padding: 0 10px;
    }
    .table_row {
      position: relative;
      height: 50px;
      padding: 0 10px;
    }
    .table_cell {
      @include table-text;
      @include media-max(1600px) {
        font-size: 13px;
      }
      @include media-max(1400px) {
        font-size: 11px;
      }
    }
    .home_screen_products_table_outer {
      & > div {
        & > div {
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 35px 170px 1fr 80px 40px;
      @include media-max(1400px) {
        grid-template-columns: 35px 120px 1fr 40px 30px;
      }
      @include media-max(900px) {
        grid-template-columns: 35px 150px 1fr 80px 40px;
      }
      @include media-max(500px) {
        grid-template-columns: 35px 100px 1fr 30px 30px;
      }
    }
  }
  .home_screen_categories_table_inner {
    .header {
      background: #e7e3e3;
      height: 50px;
      padding: 0 10px;
    }
    .table_row {
      position: relative;
      height: 50px;
      padding: 0 10px;
    }
    .table_cell {
      @include table-text;
      @include media-max(1600px) {
        font-size: 13px;
      }
      @include media-max(1400px) {
        font-size: 11px;
      }
    }
    .home_screen_categories_table_outer {
      & > div {
        & > div {
          &:nth-child(even) {
            background: #f8f8f8;
          }
        }
      }
    }
    .header,
    .table_row {
      display: grid;
      align-items: center;
      grid-template-columns: 35px 1fr 80px 40px;
      @include media-max(1400px) {
        grid-template-columns: 35px 1fr 40px 30px;
      }
      @include media-max(500px) {
        grid-template-columns: 35px 1fr 30px 40px;
      }
    }
  }
  .products_dropdown {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 0 20px;
    margin-bottom: 20px;
    .search_select_wrapper .search_select_holder .root p {
      @include media-max(500px) {
        font-size: 12px;
      }
    }
  }
  .error_msg {
    &.custom {
      margin: -10px 0 10px 0;
    }
  }
}

.cms_sections_show_section_box {
  margin-bottom: 30px;
  .update_name_form {
    padding: 20px 0 20px 0;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 0 20px;
    position: relative;
    .error_msg {
      position: absolute;
      left: 0;
      bottom: -10px;
    }
  }
}

.top_custom {
  width: 100%;
  @include flex-between;
  @include media-max(800px) {
    flex-direction: column;
    align-items: flex-start;
    .products_dropdown {
      width: 100%;
      justify-content: space-between;
    }
    .change_device_dropdown_outer {
      margin: 20px auto 0 0;
      @include media-max(500px) {
        width: 100%;
      }
    }
  }
}

.cms_home_screen_sections .align_row {
  width: 100%;
}
