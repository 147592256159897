// Mixins

@mixin media-max($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}

@mixin media-min($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin input-text-color($color) {
  -webkit-text-fill-color: $color;
  color: $color;
}

@mixin input-clear {
  border: none;
  padding: 0;
  background-color: transparent;
  appearance: none;
  outline: none;
}

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin absolute-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

@mixin fixed-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

@mixin full-size {
  width: 100%;
  height: 100%;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin text-hover {
  &:hover {
    text-decoration: none;
  }
}

@mixin button-clear {
  border: none;
  padding: 0;
  background-color: transparent;
}

@mixin inline-bg {
  width: 100%;
  height: 100%;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

@mixin cover-bg($url) {
  width: 100%;
  height: 100%;
  background: url($url);
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

@mixin full-width {
  width: 100%;
  height: 100%;
}

@mixin transition {
  transition: all 0.2s ease;
}

@mixin hover {
  @include transition;
  &:hover {
    @content;
  }
}

@mixin border($width, $color) {
  border: $width + px solid $color;
}

@mixin border-top($width, $color) {
  border-top: $width + px solid $color;
}

@mixin border-bottom($width, $color) {
  border-bottom: $width + px solid $color;
}

@mixin border-left($width, $color) {
  border-left: $width + px solid $color;
}

@mixin border-right($width, $color) {
  border-right: $width + px solid $color;
}

@mixin table-text {
  width: 90%;
  font-size: 14px;
  color: $color-text;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
