.chips_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .chip {
    height: 26px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 0.7rem;
    background: #f0f0f0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    padding: 2px 6px;
    p {
      padding-left: 6px;
    }
    span {
      margin-left: 6px;
      width: 20px;
      height: 20px;
      border-radius: $radius-round;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.15);
      @include flex-center;
      color: $color-white;
    }
  }
  .hidden {
    display: none;
  }
}
