button.button {
  @include flex-center;
  padding: 0 20px;
  height: 39px;
  background: rgba($color-purple, 1);
  color: $color-white;
  font-size: 15px;
  font-weight: 400;
  border-radius: 6px;
  letter-spacing: normal;
  text-transform: capitalize;
  font-family: $font-roboto;
  @include media-max(1400px) {
    font-size: 13px;
    padding: 0 10px;
  }
  @include hover {
    background: rgba($color-purple, 1);
    box-shadow: 0 8px 25px -8px rgba(115, 103, 240, 1);
  }
  &.default {
    background: #ededed;
    color: $color-root;
    @include hover {
      background: #ededed;
      box-shadow: 0 8px 25px -8px #b9b9b9;
    }
  }
  &.danger {
    background: $color-danger;
    @include hover {
      background: $color-danger;
      box-shadow: 0 8px 25px -8px $color-danger;
    }
  }
}

button.icon_button {
  width: 30px;
  height: 30px;
  color: rgb(185, 185, 185);
  &.disable {
    opacity: 0.2;
  }
  &.edit {
    &:hover {
      color: $color-purple;
    }
  }
  &.delete {
    &:hover {
      color: $color-danger;
    }
  }
  &.primary {
    &:hover {
      color: $color-purple;
    }
  }
  &.add {
    &:hover {
      color: $color-success;
    }
  }
  &.icon_success_color {
    background: rgba(86, 201, 58, 0.15);
    color: rgb(86, 201, 58);
    width: 25px;
    height: 25px;
    svg {
      font-size: 18px;
    }
  }
  &.icon_danger_color {
    background: rgba(234, 84, 85, 0.15);
    color: rgb(234, 84, 85);
    width: 25px;
    height: 25px;
    svg {
      font-size: 18px;
    }
  }
  &.filter_icon {
    position: relative;
    width: 35px;
    height: 35px;
    svg {
      path {
        fill: rgba($color: $color-black, $alpha: 0.4);
      }
      @include absolute-center;
      width: 25px;
      @include media-max(1400px) {
        width: 22px;
      }
    }
  }

  &.active {
    background: #f8d7da;
    color: #721c24;
    width: 25px;
    height: 25px;
    svg {
      font-size: 18px;
      @include media-max(950px) {
        font-size: 15px !important;
      }
    }
    &.active {
      background: #d4edda;
      color: #155724;
    }
  }
}

.event_icon {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: $radius-round;
  @include flex-center;
  color: $color-white;
  opacity: 0.6;
  svg {
    font-size: 20px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 10px;
    top: -10px;
    background: #e0e0e0;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 10px;
    bottom: -10px;
    background: #e0e0e0;
  }
  &.created {
    background: $color-success;
  }
  &.updated {
    background: $color-warning;
  }
  &.deleted {
    background: $color-danger;
  }
}
