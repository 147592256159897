.search_select_wrapper {
  width: 100%;
  &.no-scroll {
    margin-left: 20px;
    width: 200px;
    .dropdown_content {
      ul {
        overflow-y: unset !important;
      }
    }
  }
  &.active {
    .search_select_holder {
      border-color: $color-purple;
      height: auto;

      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      .root {
        span.icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      label {
        color: $color-purple;
      }
      .dropdown_holder {
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
        overflow: auto;
        max-height: 620px;
      }
    }
  }
  .search_select_holder {
    background: $color-white;
    width: 100%;
    min-height: 40px;
    height:auto;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 5px;
    color: rgba($color: $color-black, $alpha: 0.4);
    @include transition;
    @include border(1, rgba($color: $color-black, $alpha: 0.2));
    .root {
      padding: 0 20px;
      @include full-width;
      @include flex-between;
      cursor: pointer;
      p {
        @include flex-align-center;
        flex-wrap: wrap;
        span {
          @include flex-center;
          background: $color-grey-09;
          margin-right: 10px;
          border-radius: 4px;
          padding: 2px 5px;
          font-size: 12px;
          color: $color-text;
          svg {
            margin: 1px 0 0 2px;
          }
        }
      }
      span.icon {
        width: 15px;
        height: 15px;
        @include flex-center;
        @include transition;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(0);
        font-size: 20px;
        svg {
          margin-top: -8px;
        }
      }
    }
    label {
      display: block;
      padding-left: 10px;
      margin-bottom: 10px;
      font-size: 0.7rem;
      color: rgba($color: $color-black, $alpha: 0.4);
      white-space: nowrap;
      @include transition;
      position: absolute;
      top: -22px;
      left: 0px;
    }
    .dropdown_holder {
      @include transition;
      z-index: 10;
      position: absolute;
      top: calc(100% + 1px);
      left: -1px;
      right: -1px;
      border-radius: 5px;
      @include border(1, rgba($color: $color-black, $alpha: 0.2));
      border-top: 0;
      background: $color-white;
      padding: 10px;
      .dropdown_content {
        input {
          width: 100%;
          height: 35px;
          font-size: 13px;
          @include border(1, rgba($color: $color-black, $alpha: 0.2));
          border-radius: 5px;
          color: $color-text;
          padding: 0 10px;
          &::placeholder {
            color: rgba($color: $color-black, $alpha: 0.4);
          }
        }
        ul {
          margin-top: 10px;
          overflow: hidden;
          &.no_scroll {
            overflow-y: unset;
          }
          li {
            font-size: 13px;
            padding: 7px 10px;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            @include hover {
              background: $color-grey-10;
              cursor: pointer;
            }
            &.active {
              background: $color-grey-10;
              color: $color-text;
            }
          }
        }
        .no_result {
          margin-top: 10px;
          font-size: 13px;
        }
      }
    }
  }
}
