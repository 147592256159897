.table-categories {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 100px 70px 100px 100px 100px 100px 100px 70px;
    @include media-max(1450px) {
      grid-template-columns: 40px 30px 1fr 80px 80px 80px 80px 80px 70px;
    }
    @include media-max(1250px) {
      grid-template-columns: 40px 30px 1fr 80px 80px 80px 70px;
    }
    @include media-max(870px) {
      grid-template-columns: 40px 30px 1fr 60px 60px 70px;
    }
    @include media-max(750px) {
      grid-template-columns: 40px 30px 1fr 70px;
    }
  }
  .header {
    @include media-max(1450px) {
      padding-left: 45px !important;
      .table_cell {
        &:last-child {
          display: none !important;
        }
        &:nth-child(10) {
          display: flex !important;
        }
        &:nth-child(9) {
          display: none !important;
        }
        &:nth-child(8) {
          display: none !important;
        }
        &:nth-child(7) {
          @include media-max(1250px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          @include media-max(1250px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          @include media-max(870px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(3) {
          @include media-max(750px) {
            display: none !important;
          }
        }
      }
    }
  }
  .table_row {
    cursor: pointer;
    .table_cell {
      &:nth-child(3) {
        display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
      }
    }
    @include media-max(1450px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .table_cell {
        &:nth-child(11) {
          width: 70px !important;
        }
        &:nth-child(10) {
          display: none !important;
        }
        &:nth-child(9) {
          display: none !important;
        }
        &:nth-child(8) {
          width: 80px !important;
          @include media-max(1250px) {
            display: none !important;
          }
        }
        &:nth-child(7) {
          width: 80px !important;
          @include media-max(1250px) {
            display: none !important;
          }
        }
        &:nth-child(6) {
          width: 80px !important;
          @include media-max(870px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          width: 80px !important;
          @include media-max(870px) {
            width: 60px !important;
          }
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          width: 80px !important;
          @include media-max(870px) {
            width: 60px !important;
          }
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(3) {
          flex: 1;
        }
        &:nth-child(2) {
          width: 30px !important;
          margin: 0 10px;
        }
      }
      .expand_button {
        display: block !important;
      }
      .mobile_table_wrapper {
        display: block;
        .mobile_table_holder .mobile_table_row .row_label {
          min-width: 90px;
        }
        @include media-max(1450px) {
          .mobile_table_row:nth-child(1) {
            display: none;
            @include media-max(750px) {
              display: grid;
            }
          }
          .mobile_table_row:nth-child(2) {
            display: none;
            @include media-max(750px) {
              display: grid;
            }
          }
          .mobile_table_row:nth-child(3) {
            display: none;
            @include media-max(870px) {
              display: grid;
            }
          }
          .mobile_table_row:nth-child(4) {
            display: none;
            @include media-max(1250px) {
              display: grid;
            }
          }
          .mobile_table_row:nth-child(5) {
            display: none;
            @include media-max(1250px) {
              display: grid;
            }
          }
        }
      }
    }
  }
  .content {
    .level_1 {
      &:nth-child(even) {
        background: #f8f8f8;
      }
    }
  }
  .subcategories_wrapper.level_2 {
    background: rgb(241, 241, 241);
    padding-left: 50px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 23px;
      left: 24px;
      width: 2px;
      background: #e7e3e3;
    }
    .table_row {
      position: relative;
      @include media-max(1450px) {
        padding-left: 0 !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        left: -24px;
        height: 2px;
        background: #e7e3e3;
      }
      .table_cell {
        &:nth-child(3) {
          @include media-max(1450px) {
            width: 360px !important;
            @include media-max(650px) {
              width: calc(100% - 110px) !important;
            }
          }
        }
      }
    }
  }
  .subcategories_wrapper.level_3 {
    padding-left: 50px;
    background: $color-white;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 29px;
      left: 24px;
      width: 2px;
      background: #e7e3e3;
    }
    .table_row {
      position: relative;
      @include media-max(1450px) {
        padding-left: 0 !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        left: -24px;
        height: 2px;
        background: #e7e3e3;
      }
      .table_cell {
        &:nth-child(3) {
          @include media-max(1450px) {
            width: 360px !important;
            @include media-max(650px) {
              width: calc(100% - 110px) !important;
            }
          }
        }
      }
    }
  }
  .subcategories_wrapper.level_4 {
    padding-left: 50px;
    background: #f8f8f8;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 29px;
      left: 24px;
      width: 2px;
      background: #e7e3e3;
    }
    .table_row {
      position: relative;
      @include media-max(1450px) {
        padding-left: 0 !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        left: -24px;
        height: 2px;
        background: #e7e3e3;
      }
      .table_cell {
        &:nth-child(3) {
          @include media-max(1450px) {
            width: 360px !important;
            @include media-max(650px) {
              width: calc(100% - 110px) !important;
            }
          }
        }
      }
    }
  }
  .subcategories_wrapper.level_5 {
    padding-left: 50px;
    background: $color-white;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 29px;
      left: 24px;
      width: 2px;
      background: #e7e3e3;
    }
    .table_row {
      position: relative;
      @include media-max(1450px) {
        padding-left: 0 !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        left: -24px;
        height: 2px;
        background: #e7e3e3;
      }
      .table_cell {
        &:nth-child(3) {
          @include media-max(1450px) {
            width: 360px !important;
            @include media-max(650px) {
              width: calc(100% - 110px) !important;
            }
          }
        }
      }
    }
  }
}

.no_result.categoty_attributes {
  margin-top: 20px;
}

.category_create_attribut_form {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 60px auto;
  grid-gap: 0 10px;
  .form_field label {
    font-size: 12px;
  }
  @include media-max(600px) {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
    button {
      width: 150px;
    }
  }
  .search_select_holder {
    font-size: 12px;
  }
}

.table_categories_attributes {
  .header {
    .table_cell:nth-child(1) {
      padding-left: 8px;
    }
  }
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 30px 1fr 80px 80px 100px;
    @include media-max(700px) {
      grid-template-columns: 30px 30px 1fr 35px 45px 100px;
      padding: 0 !important;
    }
  }
  .row {
    position: relative;
    cursor: pointer;
    &:nth-child(even) {
      background: #f8f8f8;
    }
  }
  .table_category_attribute_values {
    background: #f1f1f1;
    padding-left: 50px;
    position: relative;
    @include media-max(700px) {
      padding-left: 20px;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 24px;
      left: 24px;
      width: 2px;
      background: #e7e3e3;
      @include media-max(700px) {
        left: 14px;
      }
    }
    .table_row {
      background: #f1f1f1 !important;
      position: relative;
      grid-template-columns: 50px 1fr 80px 110px 70px;
      @include media-max(700px) {
        grid-template-columns: 30px 1fr 35px 75px 70px;
        padding: 0 !important;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        left: -24px;
        height: 2px;
        background: #e7e3e3;
        @include media-max(700px) {
          width: 7px;
          left: -5px;
        }
      }
    }
  }
}

.edit_category_screen {
  .category_name {
    margin-bottom: 20px;
    font-size: 18px;
    padding: 15px 20px;
    font-weight: 500;
  }
  .inline_update_form {
    .search_select_wrapper {
      width: auto;
      margin-right: 20px;
    }
    .add_value_dropdown {
      @include flex-align-center;
    }
  }
}

.attribute_value_modal_inner {
  @include fixed-full;
  @include flex-center;
  background: rgba($color: $color-black, $alpha: 0.5);
  z-index: 9999;
  .attribute_value_modal_outer {
    background: $color-white;
    border-radius: 10px;
    padding: 50px 30px;
    display: flex;
    .MuiSwitch-root {
      margin-left: 50px;
    }
    .add_value_form {
      display: grid;
      grid-template-columns: 300px auto;
      grid-gap: 20px;
    }
    .add_value_dropdown {
      display: grid;
      grid-template-columns: 300px auto;
      grid-gap: 20px;
    }
    .search_select_wrapper {
      width: 300px;
    }
  }
}

.margin_view_more {
  width: 100%;
  font-size: small;
  flex-wrap: wrap;
  display: flex;
  margin: 1%;
  background-color: white;
  justify-content: space-around;
  padding-right: 5%;
  margin-right: 2%;
  @include media-max(1200px) {
    flex-direction: column;
    width: min-content;
    display: inline-block;
    padding: 0%;
  }
  .mobile_table_row {
    display: flex;
    justify-content: center;
    width: 15%;
    @include media-max(1200px) {
      width: 100%;
    }
    .row_label {
      text-wrap: nowrap;
      margin: auto;
      width: 50%;
      @include media-max(1200px) {
        width: 100%;
      }
    }
    .row_content {
      font-weight: bold;
      margin: auto;
      width: 50%;
      @include media-max(1200px) {
        width: 100%;
        padding-left: 3%;
      }
    }
  }
}