.expand_button {
  display: none;
}

.mobile_table_wrapper {
  display: none;
  cursor: auto;
  width: 100%;
  background: $color-white;
  position: relative;
  padding: 10px 5px;
  margin: 10px 0;
  border-top: 1px solid #e7e3e3;
  border-bottom: 1px solid #e7e3e3;
  .mobile_table_holder {
    @include full-size;
    .mobile_table_row {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: 0 20px;
      padding: 10px 0;
      position: relative;
      .row_label {
        font-weight: 500;
        font-size: 12px;
        min-width: 50px;
      }
      .row_content {
        font-weight: 300;
        font-size: 12px;
        word-break: break-all;
        position: relative;
        a {
          font-weight: 300;
          font-size: 12px;
        }
      }
    }
  }
}
