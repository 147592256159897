.form_checkbox {
  @include flex-align-center;
  margin: 20px 0;
  cursor: pointer;
  .box {
    position: relative;
    width: 20px;
    height: 20px;
    color: $color-white;
    border-radius: 2px;
    @include border(2, rgb(180, 180, 180));
    @include transition;
    transform: rotate(-90deg);
    overflow: hidden;
    &.active {
      background: $color-purple-02;
      border-color: $color-purple-02;
      transform: rotate(0);
      span {
        transform: translate(0);
      }
    }
    span {
      @include transition;
      position: absolute;
      left: 0;
      @include full-width;
      @include flex-center;
      transform-origin: right;
      padding: 3px;
      transform: translate(250%);
    }
  }
  label {
    cursor: pointer;
    color: #626262;
    display: block;
    margin-left: 5px;
    font-size: 14px;
  }
}
