.table-invoices_emails {
  .header,
  .table_row {
    display: grid;
    grid-template-columns: 1fr 60px 70px;
    align-items: center;
    @include media-max(500px) {
      grid-template-columns: 1fr 50px 70px;
    }
  }
  @include media-max(500px) {
    .table_helper {
      .align_row {
        .table_inline_form {
          width: calc(100% - 100px);
          .input_holder {
            width: calc(100% - 65px);
          }
          button {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.table-invoices_banks {
  .header,
  .table_row {
    display: grid;
    grid-template-columns: 400px 1fr 60px 70px;
    align-items: center;
    @include media-max(1000px) {
      grid-template-columns: 200px 1fr 50px 70px;
    }
    @include media-max(700px) {
      grid-template-columns: 1fr 1fr 50px 70px;
    }
  }
}

.invoices_banks_page {
  .table_helper {
    .align_row {
      @include media-max(700px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0 !important;
      }
      .table_inline_form_with_two_inputs {
        width: 100% !important;
        @include media-max(700px) {
          margin-top: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr 70px;
          grid-gap: 0 10px;
        }
        @include media-max(500px) {
          grid-template-columns: 100%;
          grid-gap: 20px 0;
        }
        .input_holder {
          margin-right: 20px;
          @include media-max(700px) {
            margin: 0;
          }
          &:nth-child(2) {
            margin-right: 0;
          }
        }
        .button {
          @include media-max(700px) {
            margin: 0;
          }
          @include media-max(500px) {
            width: 100px;
          }
        }
      }
    }
  }
  .inline_update_form {
    display: grid;
    grid-template-columns: 220px 1fr 60px;
    @include media-max(800px) {
      grid-template-columns: 160px 1fr 60px;
      grid-gap: 0 10px;
    }
    @include media-max(500px) {
      grid-template-columns: 100px 1fr 60px;
    }
    @include media-max(350px) {
      grid-template-columns: 90px 1fr 60px;
      grid-gap: 0 5px;
    }
    .input_holder {
      @include media-max(800px) {
        width: 160px !important;
      }
      @include media-max(500px) {
        width: 100px !important;
      }
      @include media-max(350px) {
        width: 90px !important;
        input {
          font-size: 12px;
          padding: 0 5px;
        }
      }
    }
  }
}
