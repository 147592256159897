.landing_page_cms {
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media-max(770px) {
    height: auto;
  }
  h1 {
    text-align: center;
  }
  .izaberi {
    background-color: black;
    color: white;
    margin-top: 10%;
    margin: auto;
    width: 25%;
    margin-bottom: 5%;
  }
  .izaberi:hover {
    color: black;
  }
  .choose {
    margin: auto;
    width: 70%;
    display: flex;
    height: 100%;
    justify-content: space-around;
    @include media-max(770px) {
      height: auto;
      flex-direction: column;
    }
    .choose_option {
      width: 30%;
      margin: auto;
      padding: 2%;
      position: relative;
      cursor: pointer;
      aspect-ratio: 1;
      img {
        width: 100%;
        object-fit: contain;
      }
      .radio_button {
        position: absolute;
        bottom: 5%;
        left: 5%;
      }
      margin-top: 5%;
      margin-bottom: 5%;
      @include media-max(770px) {
        width: 100%;
      }
    }
  }
}

.landing_page_editor {
  width: 100%;
  h1 {
    text-align: center;
  }
  .choose_catalog {
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .card {
      padding: 2%;
      cursor: pointer;
      width: 25%;
      aspect-ratio: 1;
      img {
        object-fit: contain;
        width: 100%;
        aspect-ratio: 1;
      }
    }
  }

  .form_first {
    padding: 2%;
    p {
      snap {
        color: #0787ea;
        font-weight: bold;
      }
      input {
        border: 1px solid #0787ea;
        font-size: medium;
        padding-left: 0.5%;
      }
    }
    button {
      padding: 1%;
      background-color: black;
      color: white;
    }
    .content {
      width: 100%;
      margin-top: 2%;
      margin-bottom: 2%;

      .header {
        width: 100%;
        display: flexbox;

        display: flex;
        justify-content: space-between;
        .dropzone {
          width: 30%;
          position: relative;
          margin: auto;
          aspect-ratio: 1;
          height: fit-content;
          background-color: rgba(245, 245, 245, 0.459);
          img {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
          }
          input {
            cursor: pointer;
            filter: opacity(0);
            width: 100%;
            height: 100%;
          }
          label {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
              margin: auto;
              color: gray;
              font-weight: bold;
            }
          }
        }
        .dropzone_card {
          min-width: 15%;
          position: relative;
          margin: auto;
          aspect-ratio: 1;
          height: fit-content;
          background-color: rgba(245, 245, 245, 0.459);
          img {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
          }
          input {
            cursor: pointer;
            filter: opacity(0);
            width: 100%;
            height: 100%;
          }
          label {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
              margin: auto;
              color: gray;
              font-weight: bold;
            }
          }
        }
        .editor_header {
          width: 70%;
          padding: 1%;

          .tab {
            margin-top: 2%;
            width: 100%;
            display: flex;
            justify-content: space-around;
            .tab_image {
              margin: auto;
              min-width: 15%;
              position: relative;
              aspect-ratio: 1;
              height: fit-content;
              background-color: rgba(245, 245, 245, 0.459);
              border: 1px solid #0787ea;
              img {
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
              }
              input {
                cursor: pointer;
                filter: opacity(0);
                width: 100%;
                height: 100%;
              }
              label {
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                p {
                  margin: auto;
                  color: gray;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .body {
        width: 100%;
        margin-top: 2%;
        .product_preview {
          max-width: 100%;
          min-height: 25em;
          margin-bottom: 2%;
          display: block;
          flex-wrap: wrap;
          .item {
            display: inline-block;
          }
        }
      }
    }
  }
  .form_second {
    padding: 2%;
    p {
      snap {
        color: #0787ea;
        font-weight: bold;
      }
      input {
        border: 1px solid #0787ea;
        font-size: medium;
        padding-left: 0.5%;
      }
    }
    button {
      padding: 1%;
      background-color: black;
      color: white;
    }
    .content {
      width: 100%;
      margin-top: 2%;
      margin-bottom: 2%;

      .header {
        width: 100%;
        display: flexbox;

        .dropzone {
          width: 100%;
          position: relative;
          margin: auto;
          height: 200px;
          height: fit-content;
          background-color: rgba(245, 245, 245, 0.459);
          img {
            height: 200px;
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            object-fit: cover;
            z-index: 1;
          }
          input {
            height: 200px;
            cursor: pointer;
            filter: opacity(0);
            width: 100%;
          }
          label {
            height: 200px;
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
              margin: auto;
              color: gray;
              font-weight: bold;
            }
          }
        }
        .editor_header {
          width: 100%;
          padding: 1%;

          .tab {
            margin-top: 2%;
            width: 100%;
            display: flex;
            justify-content: space-around;
            .tab_image {
              margin: auto;
              width: 15%;
              position: relative;
              aspect-ratio: 1;
              height: fit-content;
              background-color: rgba(245, 245, 245, 0.459);
              border: 1px solid #0787ea;
              img {
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
              }
              input {
                cursor: pointer;
                filter: opacity(0);
                width: 100%;
                height: 100%;
              }
              label {
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                p {
                  margin: auto;
                  color: gray;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .body {
        width: 100%;
        margin-top: 2%;
        .product_preview {
          width: 100%;
          min-height: 25em;
          margin-bottom: 2%;
        }
        .cards {
          display: flex;
          justify-content: center;
          .card {
            width: 25%;
            margin: 1%;
          }
          .add {
            margin-left: 2%;
            margin-right: 2%;
            height: 100%;
            width: 15%;
            padding: 2%;

            border-radius: 1vw;
            box-shadow: 0px 0px 3px rgb(184, 184, 184);
            display: flex;
            justify-content: center;
            .icon {
              margin: auto;
              width: 33%;
              aspect-ratio: 1;
              height: fit-content;
              color: gray;
            }
            .icon:hover {
              color: black;
            }
          }
        }
      }
    }
  }
  .form_third {
    padding: 2%;
    p {
      snap {
        color: #0787ea;
        font-weight: bold;
      }
      input {
        border: 1px solid #0787ea;
        font-size: medium;
        padding-left: 0.5%;
      }
    }
    button {
      padding: 1%;
      background-color: black;
      color: white;
    }
    .content {
      width: 100%;
      margin-top: 2%;
      margin-bottom: 2%;

      .header {
        width: 100%;
        display: flexbox;
        flex-direction: row-reverse;
        display: flex;
        justify-content: space-between;
        .dropzone {
          width: 30%;
          position: relative;
          margin: auto;
          aspect-ratio: 1;
          height: fit-content;
          background-color: rgba(245, 245, 245, 0.459);
          img {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
          }
          input {
            cursor: pointer;
            filter: opacity(0);
            width: 100%;
            height: 100%;
          }
          label {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
              margin: auto;
              color: gray;
              font-weight: bold;
            }
          }
        }
        .dropzone_card {
          min-width: 15%;
          position: relative;
          margin: auto;
          aspect-ratio: 1;
          height: fit-content;
          background-color: rgba(245, 245, 245, 0.459);
          img {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
          }
          input {
            cursor: pointer;
            filter: opacity(0);
            width: 100%;
            height: 100%;
          }
          label {
            top: 0;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
              margin: auto;
              color: gray;
              font-weight: bold;
            }
          }
        }
        .editor_header {
          width: 70%;
          padding: 1%;

          .tab {
            margin-top: 2%;
            width: 100%;
            display: flex;
            justify-content: space-around;
            .tab_image {
              margin: auto;
              width: 15%;
              position: relative;
              aspect-ratio: 1;
              height: fit-content;
              background-color: rgba(245, 245, 245, 0.459);
              border: 1px solid #0787ea;
              img {
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
              }
              input {
                cursor: pointer;
                filter: opacity(0);
                width: 100%;
                height: 100%;
              }
              label {
                top: 0;
                left: 0;
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                p {
                  margin: auto;
                  color: gray;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

      .categories {
        width: 100%;
        display: block;
        .category {
          width: 20%;
          aspect-ratio: 1;
          display: inline-block;
        }
      }

      .body {
        width: 100%;
        margin-top: 2%;
        .product_preview {
          width: 100%;
          min-height: 25em;
          margin-bottom: 2%;
        }
      }
    }
  }
}

.input_link {
  color: #0787ea;
  cursor: pointer;
  text-align: right;
  position: relative;

  .link_form {
    position: absolute;
    top: 100%;
    right: 0;
    width: 33%;
    height: 35px;
    border: 1px solid #0787ea;
    border-radius: 0.5vw;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .icon {
      margin: auto;
    }
    .button {
      height: 100%;
      background-color: white;
      color: #0787ea;
      border-radius: 0;
    }
    input {
      border: 0px;
      width: 80%;
    }
  }
}

.body_preview {
  width: 100%;
  padding-bottom: 2%;
  height: 100%;
  margin-top: 2%;
  justify-content: center;
  .add {
    margin: 2%;
    height: 100%;
    width: 15%;
    padding: 2%;

    border-radius: 1vw;
    box-shadow: 0px 0px 3px rgb(184, 184, 184);
    display: flex;
    justify-content: center;
    .icon {
      margin: auto;
      width: 33%;
      aspect-ratio: 1;
      height: fit-content;
      color: gray;
    }
    .icon:hover {
      color: black;
    }
  }
  .add:hover {
    cursor: pointer;
  }
  .item {
    width: 15%;
    margin-left: 2%;
    margin-top: 2%;
    position: relative;
    display: inline-block;
    .remove_button {
      position: absolute;
      top: 0;
      left: 0;
      padding: 1%;
      z-index: 9999;
      .icon {
        cursor: pointer;
      }
    }
  }
}
.add_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
  justify-content: center;

  background-color: rgba(105, 105, 105, 0.212);
  .form {
    padding: 2%;
    width: 50%;
    min-height: 25em;
    background-color: white;
    max-height: 50vh;
    overflow-y: scroll;
    margin: auto;
    position: relative;
    .close {
      width: 4%;
      margin-top: 1%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999;
      height: 5%;
      aspect-ratio: 1;
      cursor: pointer;
    }
    .choose {
      margin-top: 2%;
    }
  }
}

.landing_table {
  background-color: white;
  .icon_action {
    width: 25%;
    height: 20px;
    cursor: pointer;
    color: gray;
  }
  .icon_action:hover {
    color: black;
  }
}

.landing_menu {
  width: 100%;
  background-color: white;
  margin-bottom: 2%;
  padding: 1%;

  .nazad_button {
    border: 1px solid black;
    color: black;
  }
  .nazad_button:hover {
    color: black;
  }
  .dodaj_button {
    border: 1px solid black;
    color: black;
  }
  .dodaj_button:hover {
    color: black;
  }
}

.delete_modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.295);
  z-index: 99999;
  display: flex;
  justify-content: center;
  .body {
    padding: 2%;
    margin: auto;
    min-height: 200px;
    background-color: white;
    h2 {
      text-align: center;
      margin: 2%;
    }
    p {
      text-align: center;
    }
    .link {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: auto;
      flex-direction: column;
    }
    .buttons {
      width: 100%;

      display: flex;
      justify-content: center;

      .izbrisi {
        background-color: black;
        color: white;
        width: 25%;
        margin: 2%;
      }
      .nazad {
        border: 1px solid black;
        height: min-content;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}
