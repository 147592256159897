.table-users {
  .header,
  .table_row {
    display: grid;
    align-items: center;
    grid-template-columns: 400px 300px 1fr 70px;
    @include media-max(1100px) {
      grid-template-columns: 1fr 1fr 200px 70px;
    }
    @include media-max(900px) {
      grid-template-columns: 1fr 1fr 70px;
    }
    @include media-max(750px) {
      grid-template-columns: 1fr 70px;
    }
    @include media-max(500px) {
      grid-template-columns: 1fr 60px;
    }
  }
  .header {
    @include media-max(900px) {
      padding-left: 45px !important;
      .table_cell {
        &:first-child {
          display: flex !important;
        }
        &:nth-child(2) {
          display: flex !important;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(4) {
          display: flex !important;
        }
        display: none;
      }
    }
  }
  .table_row {
    @include media-max(900px) {
      @include flex-align-center;
      flex-wrap: wrap;
      height: unset !important;
      padding: 8px 10px !important;
      .expand_button {
        display: block;
      }
      .table_cell {
        &:nth-child(2) {
          margin-left: 10px;
          display: flex !important;
          flex: 1;
          a {
            width: 100% !important;
          }
        }
        &:nth-child(3) {
          display: flex !important;
          flex: 1;
          @include media-max(750px) {
            display: none !important;
          }
        }
        &:nth-child(5) {
          display: flex !important;
          width: 70px !important;
          @include media-max(500px) {
            width: 60px !important;
          }
        }
        display: none;
      }
    }
  }
  .mobile_table_wrapper {
    @include media-max(900px) {
      display: block;
      .mobile_table_row:nth-child(1) {
        display: none;
        @include media-max(750px) {
          display: grid;
        }
      }
    }
  }
}
.user_avatar_part {
  .error_msg {
    text-align: center;
  }
}
.user_avatar_section {
  display: flex;
  width: 190px;
  height: 190px;
  position: relative;
  border-radius: 8px;
  background: $color-grey-10;
  @include media-max(1000px) {
    width: 160px;
    height: 160px;
  }
  .avatar {
    @include full-width;
  }
  &:hover {
    .photo {
      opacity: 1;
    }
  }
  input[type='file'] {
    font-size: 0;
    opacity: 0;
    @include absolute-full;
    cursor: pointer;
  }
  .photo {
    opacity: 0;
    @include transition;
    @include absolute-full;
    @include flex-center;
    background: rgba($color: $color-black, $alpha: 0.4);
    border-radius: 8px;
    @include media-max(800px) {
      opacity: 1;
    }
    .trash {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      width: 40px;
      height: 40px;
      @include media-max(800px) {
        width: 30px;
        height: 30px;
        top: 3px;
        right: 3px;
      }
      svg {
        color: $color-danger;
        font-size: 1.8rem;
        @include media-max(800px) {
          font-size: 1.5rem;
        }
      }
    }
  }
  svg {
    color: $color-white;
    font-size: 2.8em;
    @include media-max(800px) {
      font-size: 2rem;
    }
  }
}

.users_form_row {
  display: grid;
  grid-template-columns: 190px 1fr;
  grid-gap: 0 50px;
  @include media-max(1000px) {
    grid-template-columns: 160px 1fr;
    grid-gap: 0 30px;
  }
  @include media-max(800px) {
    grid-template-columns: 100%;
    grid-gap: 20px 0;
    .form_group {
      grid-gap: 30px 0 !important;
    }
  }
  .form_group {
    grid-template-columns: 100% !important;
  }
}

.create_user_page {
  .dropdown_inner {
    width: 100%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    &.active {
      .dropdown_outer {
        svg {
          transform: rotate(180deg);
        }
      }
    }
    .dropdown_outer {
      @include full-size;
      padding: 0 20px;
      @include flex-between;
      cursor: pointer;
      p {
        color: $color-text;
      }
      svg {
        transform: rotate(0);
        @include transition;
      }
    }
    .dropdown_items {
      position: absolute;
      left: 0;
      right: 0;
      top: 110%;
      background: #fff;
      z-index: 2;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
      li {
        padding: 5px 20px;
        font-size: 16px;
        cursor: pointer;
        &.active {
          color: $color-text;
          background: $color-grey-10;
        }
      }
    }
  }
}
